<template>
  <div class="page">
    <div class="page-header">
      <el-button type="primary" size="small" @click="addItem">
        {{ $t('otherResources.uploadfiles') }}
      </el-button>
    </div>
    <div class="page-main">
      <div class="page-tools">
        <el-form :inline="true" :model="tabelSearch">
          <el-form-item>
            <el-button
              type="primary"
              size="small"
              @click="deleteItem()"
              :disabled="!tableSelection.length"
              >{{ $t('delete') }}
            </el-button>
          </el-form-item>
          <el-form-item class="page-tools-right keywords">
            <el-input
              v-model="tabelSearch.keywords"
              size="small"
              :placeholder="$t('edm001')"
              prefix-icon="el-icon-search"
              clearable
              @change="getTabelData"
            ></el-input>
          </el-form-item>
          <el-form-item class="page-tools-right">
            <dist-select
              v-model="tabelSearch.fileType"
              size="small"
              :optionData="OtherResourcesTypes"
              :selectAll="{ label: this.$t('otherResources.allTypes'), value: null }"
              @change="getTabelData"
            ></dist-select>
          </el-form-item>
        </el-form>
      </div>
      <table-pagination
        memory="otherResources"
        ref="refTable"
        :height="pageTabelHeight"
        :tableData="tableData"
        :columnData="columnData"
        selectionShow
        @selection-change="selectionChange"
        optionShow
        columnOptionShow
        :total="tabelTotal"
        :currentPage.sync="tabelSearch.currentPage"
        :pageSize.sync="tabelSearch.pageSize"
        @changeCurrentPage="getTabelData"
        @sort-change="tabelSortChange"
      >
        <!-- option -->
        <template slot="option" slot-scope="scope">
          <el-tooltip effect="dark" :content="$t('push')" placement="top" :open-delay="1000">
            <el-button type="text" class="btn-p0" @click="pushItem(scope.row)">
              <i class="el-icon-s-promotion iconfont icon-push"></i>
            </el-button>
          </el-tooltip>
          <el-tooltip effect="dark" :content="$t('edit')" placement="top" :open-delay="1000">
            <el-button type="text" class="btn-p0" @click="editItem(scope.row.id)">
              <i class="el-icon-edit iconfont icon-edit"></i>
            </el-button>
          </el-tooltip>
          <el-tooltip effect="dark" :content="$t('download')" placement="top" :open-delay="1000">
            <el-button type="text" class="btn-p0" @click="downloadItem(scope.row)">
              <i class="el-icon-download"></i>
            </el-button>
          </el-tooltip>
        </template>
      </table-pagination>
    </div>
    <!-- 推送任务 -->
    <dialog-configuration-push
      ref="configurationPush"
      v-if="pushVisible"
      :visible.sync="pushVisible"
      :title="pushTitle"
      :columnData="columnData2"
      :req="pushReq"
      @scheduled="scheduledChange"
    ></dialog-configuration-push>
    <!-- 推送定时任务 -->
    <dialog-scheduled-update
      v-if="scheduledUpdateVisible"
      :visible.sync="scheduledUpdateVisible"
      :req="scheduledUpdateReq"
    ></dialog-scheduled-update>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { TablePagination, DistSelect } from '@/components'
import {
  resourcesSearch,
  resourcesRemoveBatch,
  // resourcesDownLoad
} from '@/api/otherResources'
import DialogConfigurationPush from '../../Configuration/dailog-push/dialog-configuration-push'
import DialogScheduledUpdate from '../../Configuration/dailog-push/dialog-scheduled-update'
import axios from '@/plugins/axios.js'
import { downloadFile } from '@/plugins/methods'



export default {
  name: 'OtherResources',
  components: {
    TablePagination,
    DistSelect,
    DialogConfigurationPush,
    DialogScheduledUpdate,
  },
  data() {
    return {
      tabelSearch: {
        currentPage: 1,
        pageSize: 10,
        sortField: 'updateTime',
        sortOrder: 'desc',
        keywords: '',
        fileType: null,
      },
      tabelTotal: 0,
      columnData: [
        {
          label: this.$t('edm001'),
          prop: 'resourceName',
          sortable: 'custom',
          minWidth: '160',
        },
        {
          label: this.$t('otherResources.fileType'),
          prop: 'fileType',
          minWidth: '170',
          filter: (val) => this.OtherResourcesTypes[val],
        },
        {
          label: this.$t('otherResources.fileName'),
          prop: 'fileName',
          minWidth: '160',
        },

        {
          label: this.$t('ssite.site'),
          prop: 'site',
          minWidth: '160',
        },
        {
          label: this.$t('edm002'),
          prop: 'description',
          minWidth: '160',
        },
        {
          label: this.$t('firmware.fileSize'),
          prop: 'fileSize',
          minWidth: '120',
        },
        {
          label: this.$t('lastUpdated'),
          prop: 'updateTime',
          sortable: 'custom',
          minWidth: '160',
          filter: 'dateFormat',
        },
        // {
        //   label: this.$t('model.description'),
        //   prop: 'firmwareDesc',
        //   minWidth: '160',
        // },

      ],
      tableData: [],
      tableSelection: [],
      // 实时推送
      pushVisible: false,
      pushTitle: '',
      columnData2: [
        {
          label: this.$t('device.macAddress'),
          prop: 'macAddress',
          minWidth: 150,
          filter: 'toUpperCase',
        },
        {
          label: this.$t('device.deviceName'),
          prop: 'deviceName',
          minWidth: 150,
        },
        {
          label: this.$t('device.deviceModel'),
          prop: 'deviceModel',
          minWidth: 120,
        },
      ],
      pushReq: {},
      // 定时推送
      scheduledUpdateVisible: false,
      scheduledUpdateReq: {},
    }
  },
  computed: {
    ...mapGetters('dist', ['OtherResourcesTypes']),
    ...mapState('memory', ['sidebarSite']),
    ...mapGetters('session', ['pageTabelHeight']),
    tabelParams() {
      let data = { ...this.tabelSearch }
      data.site = []
      return data
    },
  },
  watch: {
    'tabelParams.site'() {
      this.getTabelData()
    },
  },
  created() {
    this.getTabelData()
  },
  methods: {
    // 获取数据
    getTabelData() {
      resourcesSearch(this.tabelParams)
        .then(({ data }) => {
          this.tableData = data.result.rows // 表格数据
          this.tabelTotal = data.result.totalRows // 表格数据条目
          // this.$refs.refTable.clearFilter() // 表格过滤清空
        })
        .catch(() => {})
    },
    // 排序
    tabelSortChange({ order, prop }) {
      this.tabelSearch.sortOrder = order ? order : 'desc'
      this.tabelSearch.sortField = order ? prop : 'updateTime'
      this.getTabelData()
    },
    // 多选
    selectionChange(val) {
      this.tableSelection = val
    },
    // 编辑
    addItem() {
      this.$router.push('/otherResources/uploadFiles')
    },
    // 编辑
    editItem(id) {
      this.$router.push({ path: '/otherResources/editFile', query: { id, pageType : 'edit' } })
    },
    // 删
    deleteItem() {
      this.$confirm(this.$t('firmware.deleteTip'), this.$t('tip'), {
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel'),
        type: 'warning',
      })
        .then(() => {
          const ids = this.tableSelection.map((e) => e.id)
          resourcesRemoveBatch(ids)
            .then(({ data }) => {
              this.$message.success(data.message)
              this.getTabelData()
            })
            .catch(() => {})
        })
        .catch(() => {})
    },
    // 下载
    downloadItem({ id }) {
      axios.post('/tb-resources/downLoadFileNew',{id: id},{ responseType: 'blob' })
        .then((res)=>{
          downloadFile(res)
        })
    },
    // 推送
    pushItem(row) {
      this.pushVisible = true
      this.pushReq.row = {
        id: row.id,
        relationName: 'otherResources',
      }
    },
    // 推送定时任务
    scheduledChange(val) {
      this.scheduledUpdateVisible = true
      this.scheduledUpdateReq.data = val
    },
  },
}
</script>

<style lang="scss" scoped>
 
</style>
